import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import throttle from 'lodash/throttle';
import ConnectionProvider from './pages/kiosk/ConnectionProvider';
import get from 'lodash/get';
import find from 'lodash/find';

export default withRouter(({ session, organizationId, children, onLogout }) => {
  useEffect(() => {
    const isSuperAdmin = !!find(get(session, 'user.metadata', []), _ => _ === 'SuperAdmin');
    // SuperAdmin should be logged out after 3 hours
    const timeout = isSuperAdmin ? 3 * 3600 * 1000 : (session.sessionTimeout || 10 * 60) * 1000;

    const logout = () => {
      session.destroy();
      if (onLogout) {
        onLogout();
      }
    };

    const throttledLogout = throttle(logout, timeout / 2, { leading: true, trailing: true });

    const setLogOutTimeout = () => setTimeout(throttledLogout, timeout);

    let logOutTimeout = setLogOutTimeout();

    const postponeLogout = () => {
      clearTimeout(logOutTimeout);
      logOutTimeout = setLogOutTimeout();
    };

    window.addEventListener('scroll', postponeLogout);
    window.addEventListener('focus', postponeLogout);
    window.addEventListener('blur', postponeLogout);
    window.addEventListener('click', postponeLogout);

    return () => {
      clearTimeout(logOutTimeout);
      window.removeEventListener('scroll', postponeLogout);
      window.removeEventListener('focus', postponeLogout);
      window.removeEventListener('blur', postponeLogout);
      window.removeEventListener('click', postponeLogout);
    };
  }, [session, onLogout]);

  return (
    <ConnectionProvider
      accessToken={session.token}
      organizationId={organizationId ?? session?.membership?.organization?.id}
    >
      {children}
    </ConnectionProvider>
  );
});
