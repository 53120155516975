import React, { FC, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Aggregation, AggregationContext } from '../Aggregation';
import Box from '@material-ui/core/Box';
import Header from '../components/Header';
import Divider from '@material-ui/core/Divider';
import TabNavigation from '../../sensor/TabNavigation';
import { Route, Switch } from 'react-router-dom';
import ORUtilizationAnalytics from '../ORUtilizationAnalytics';
import PhysicianUtilizationAnalytics from '../PhysicianUtilizationAnalytics';
import GeneralAnalytics from '../GeneralAnalytics';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Filters from '../../analytics/Filters';
import { analyticsConfigQuery } from '../../../../graph/dashboard';
import { useQuery } from '@apollo/client';

interface AggregationPickerProps {
  value: Aggregation;
  onChange: (value: Aggregation) => void;
}

const AggregationPicker = ({ value, onChange }: AggregationPickerProps) => {
  const handleAggregationChange = (_: React.MouseEvent<HTMLElement>, value: any) => {
    const parsed = Aggregation.safeParse(value);
    onChange(parsed.success ? parsed.data : 'average');
  };

  return (
    <Box display="flex" alignItems="center" mr={2}>
      <ToggleButtonGroup size="small" value={value} exclusive onChange={handleAggregationChange}>
        <ToggleButton value="average">Average</ToggleButton>
        <ToggleButton value="median">Median</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

const tabs = match => [
  {
    id: 'general',
    name: 'General',
    pathname: match.url,
  },
  {
    id: 'operating-room',
    name: 'Analysis by OR',
    pathname: `${match.url}/operating-room`,
  },
  {
    id: 'physician',
    name: 'Analysis by Physician',
    pathname: `${match.url}/physician`,
  },
];

const AnalyticsV2: FC<
  { filter: any; setFilter: any; isBusinessManager: boolean; physicianIdFromMetadata: number } & RouteComponentProps
> = ({ filter, setFilter, isBusinessManager, location, match, physicianIdFromMetadata }) => {
  const classes = useStyles();
  const shouldHideInputs = location.pathname.includes(`/physician`);

  const initialAggregation = useMemo(() => {
    const aggregation = localStorage.getItem('analytics-aggregation');
    const parsed = Aggregation.safeParse(aggregation);
    return parsed.success ? parsed.data : 'average';
  }, []);

  const [aggregation, setAggregation] = useState<Aggregation>(initialAggregation);

  const handleAggregationChange = (value: Aggregation) => {
    setAggregation(value);
    localStorage.setItem('analytics-aggregation', value);
  };

  const analyticsConfig = useQuery(analyticsConfigQuery);
  const totalPatientsThreshold = analyticsConfig?.data?.analyticsConfiguration?.totalPatientsThreshold;
  const waitingRoomTimeThreshold = analyticsConfig?.data?.analyticsConfiguration?.waitingRoomTimeThreshold;
  const preopTimeThreshold = analyticsConfig?.data?.analyticsConfiguration?.preopTimeThreshold;
  const readyForAnesthesiaThreshold = analyticsConfig?.data?.analyticsConfiguration?.readyForAnesthesiaThreshold;
  const readyForORThreshold = analyticsConfig?.data?.analyticsConfiguration?.readyForORThreshold;
  // analyticsConfig?.data?.analyticsConfiguration?.orUtilThreshold,
  const orTimeThreshold = analyticsConfig?.data?.analyticsConfiguration?.orTimeThreshold;
  const cleaningTimeThreshold = analyticsConfig?.data?.analyticsConfiguration?.cleaningTimeThreshold;
  const closingTimeThreshold = analyticsConfig?.data?.analyticsConfiguration?.closingTimeThreshold;
  const popiTimeThreshold = analyticsConfig?.data?.analyticsConfiguration?.popiTimeThreshold;
  const pacuTimeThreshold = analyticsConfig?.data?.analyticsConfiguration?.pacuTimeThreshold;
  const postOpTimeThreshold = analyticsConfig?.data?.analyticsConfiguration?.postOpTimeThreshold;
  const popiMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.popiMinThreshold;
  const popiMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.popiMaxThreshold;
  const wrMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.wrMinThreshold;
  const wrMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.wrMaxThreshold;
  const preOpMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.preOpMinThreshold;
  const preOpMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.preOpMaxThreshold;
  const cleaningMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.cleaningMinThreshold;
  const cleaningMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.cleaningMaxThreshold;
  const pacuMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.pacuMinThreshold;
  const pacuMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.pacuMaxThreshold;
  const postOpMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.postOpMinThreshold;
  const postOpMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.postOpMaxThreshold;
  const inOrMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.inOrMinThreshold;
  const inOrMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.inOrMaxThreshold;
  const pipoMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.pipoMinThreshold;
  const pipoMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.pipoMaxThreshold;
  const preOpToDischargedMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.preOpToDischargedMinThreshold;
  const preOpToDischargedMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.preOpToDischargedMaxThreshold;
  const inSurgeryMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.inSurgeryMinThreshold;
  const inSurgeryMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.inSurgeryMaxThreshold;
  const closingMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.closingMinThreshold;
  const closingMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.closingMaxThreshold;
  const procedureCompleteMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.procedureCompleteMinThreshold;
  const procedureCompleteMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.procedureCompleteMaxThreshold;
  const orReadyMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.orReadyMinThreshold;
  const orReadyMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.orReadyMaxThreshold;
  const samePhysicianORTurnover = analyticsConfig?.data?.analyticsConfiguration?.samePhysicianORTurnover;
  const piToStartMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.piToStartMinThreshold;
  const piToStartMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.piToStartMaxThreshold;
  const poToCutMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.poToCutMinThreshold;
  const poToCutMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.poToCutMaxThreshold;
  const startToCloseMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.startToCloseMinThreshold;
  const startToCloseMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.startToCloseMaxThreshold;
  const physicianPopiMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.physicianPopiMinThreshold;
  const physicianPopiMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.physicianPopiMaxThreshold;
  const fcotsGracePeriod = analyticsConfig?.data?.analyticsConfiguration?.fcotsGracePeriod;
  const piToPhyiMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.piToPhyiMinThreshold;
  const piToPhyiMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.piToPhyiMaxThreshold;
  const closingCompleteToPatientOutMinThreshold =
    analyticsConfig?.data?.analyticsConfiguration?.closingCompleteToPatientOutMinThreshold;
  const closingCompleteToPatientOutMaxThreshold =
    analyticsConfig?.data?.analyticsConfiguration?.closingCompleteToPatientOutMaxThreshold;

  const thresholds = {
    totalPatientsThreshold: totalPatientsThreshold,
    waitingRoomTimeThreshold: waitingRoomTimeThreshold,
    preopTimeThreshold: preopTimeThreshold,
    readyForAnesthesiaThreshold: readyForAnesthesiaThreshold,
    readyForORThreshold: readyForORThreshold,
    orTimeThreshold: orTimeThreshold,
    cleaningTimeThreshold: cleaningTimeThreshold,
    closingTimeThreshold: closingTimeThreshold,
    popiTimeThreshold: popiTimeThreshold,
    pacuTimeThreshold: pacuTimeThreshold,
    postOpTimeThreshold: postOpTimeThreshold,
    pipoMinThreshold: pipoMinThreshold,
    pipoMaxThreshold: pipoMaxThreshold,
    popiMinThreshold: popiMinThreshold,
    popiMaxThreshold: popiMaxThreshold,
    wrMinThreshold: wrMinThreshold,
    wrMaxThreshold: wrMaxThreshold,
    preOpMinThreshold: preOpMinThreshold,
    preOpMaxThreshold: preOpMaxThreshold,
    cleaningMinThreshold: cleaningMinThreshold,
    cleaningMaxThreshold: cleaningMaxThreshold,
    pacuMinThreshold: pacuMinThreshold,
    pacuMaxThreshold: pacuMaxThreshold,
    postOpMinThreshold: postOpMinThreshold,
    postOpMaxThreshold: postOpMaxThreshold,
    preOpToDischargedMinThreshold: preOpToDischargedMinThreshold,
    preOpToDischargedMaxThreshold: preOpToDischargedMaxThreshold,
    inOrMinThreshold: inOrMinThreshold,
    inOrMaxThreshold: inOrMaxThreshold,
    inSurgeryMinThreshold: inSurgeryMinThreshold,
    inSurgeryMaxThreshold: inSurgeryMaxThreshold,
    closingMinThreshold: closingMinThreshold,
    closingMaxThreshold: closingMaxThreshold,
    procedureCompleteMinThreshold: procedureCompleteMinThreshold,
    procedureCompleteMaxThreshold: procedureCompleteMaxThreshold,
    orReadyMinThreshold: orReadyMinThreshold,
    orReadyMaxThreshold: orReadyMaxThreshold,
    samePhysicianORTurnover: samePhysicianORTurnover,
    piToStartMinThreshold: piToStartMinThreshold,
    piToStartMaxThreshold: piToStartMaxThreshold,
    poToCutMinThreshold: poToCutMinThreshold,
    poToCutMaxThreshold: poToCutMaxThreshold,
    startToCloseMinThreshold: startToCloseMinThreshold,
    startToCloseMaxThreshold: startToCloseMaxThreshold,
    physicianPopiMinThreshold: physicianPopiMinThreshold,
    physicianPopiMaxThreshold: physicianPopiMaxThreshold,
    fcotsGracePeriod: fcotsGracePeriod,
    piToPhyiMinThreshold: piToPhyiMinThreshold,
    piToPhyiMaxThreshold: piToPhyiMaxThreshold,
    closingCompleteToPatientOutMinThreshold: closingCompleteToPatientOutMinThreshold,
    closingCompleteToPatientOutMaxThreshold: closingCompleteToPatientOutMaxThreshold,
  };

  return (
    <Box className={classes.root}>
      <Header filter={filter} thresholds={thresholds} />
      <Divider />
      <Switch>
        <Route
          path={`${match.path}/operating-room`}
          render={() => (
            <Box className={classes.toolBar}>
              <Box className={classes.navAndAggregation}>
                <Box>
                  <TabNavigation tabs={tabs(match)} location={location} />
                </Box>
                <Box className={classes.buttonGroup}>
                  <AggregationPicker value={aggregation} onChange={handleAggregationChange} />
                </Box>
              </Box>
              <Box flex={1} alignSelf="stretch" className={classes.filters}>
                <Filters
                  onChange={setFilter}
                  value={filter}
                  reload={false}
                  dontReloadOn={['operationRooms']}
                  showOperationRoomsSelectInput
                  showPhysiciansSelectInput={!physicianIdFromMetadata}
                  hidePhysicianSelectInput
                  hideSpecialitySelectInput={!!physicianIdFromMetadata}
                />
              </Box>
            </Box>
          )}
        />
        <Route
          path={`${match.path}/physician`}
          render={() => (
            <Box className={classes.toolBar}>
              <Box className={classes.navAndAggregation}>
                <Box>
                  <TabNavigation tabs={tabs(match)} location={location} />
                </Box>
                <Box className={classes.buttonGroup}>
                  <AggregationPicker value={aggregation} onChange={handleAggregationChange} />
                </Box>
              </Box>
              <Box flex={1} alignSelf="stretch" className={classes.filters}>
                <Filters
                  onChange={setFilter}
                  value={filter}
                  reload={false}
                  dontReloadOn={['operationRooms']}
                  showOperationRoomsSelectInput
                  showPhysiciansSelectInput={!physicianIdFromMetadata}
                  hidePhysicianSelectInput
                  hideSpecialitySelectInput={!!physicianIdFromMetadata}
                />
              </Box>
            </Box>
          )}
        />
        <Route
          path={`*`}
          render={() => (
            <Box className={classes.toolBar} flexDirection="column">
              <Box flex={1} alignSelf="start">
                <TabNavigation tabs={tabs(match)} location={location} />
              </Box>
              <Box flex={1} alignSelf="stretch" className={classes.filters}>
                <Filters
                  onChange={setFilter}
                  value={filter}
                  reload={false}
                  dontReloadOn={['operationRooms']}
                  showOperationRoomsSelectInput
                  showPhysiciansSelectInput={!physicianIdFromMetadata}
                  hidePhysicianSelectInput
                  hideSpecialitySelectInput={!!physicianIdFromMetadata}
                />
              </Box>
            </Box>
          )}
        />
      </Switch>

      <AggregationContext.Provider value={aggregation}>
        <Switch>
          <Route
            path={`${match.path}/operating-room`}
            render={() => <ORUtilizationAnalytics filter={filter} setFilter={setFilter} />}
          />
          <Route
            path={`${match.path}/physician`}
            render={() => <PhysicianUtilizationAnalytics filter={filter} setFilter={setFilter} />}
          />
          <Route
            path={`*`}
            render={() => (
              <GeneralAnalytics
                filter={filter}
                setFilter={setFilter}
                isBusinessManager={isBusinessManager}
                thresholds={thresholds}
              />
            )}
          />
        </Switch>
      </AggregationContext.Provider>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  toolBar: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  navAndAggregation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },
  loading: {
    position: 'absolute',
    margin: 'auto',
  },
  actions: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  tabs: {
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
  },
  buttonGroup: {
    '&:empty': {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      order: 2,
    },
  },
  filters: {
    [theme.breakpoints.down('md')]: {
      order: 1,
    },
  },
}));

export default AnalyticsV2;
