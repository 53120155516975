import React, { FC, Fragment, useMemo } from 'react';
import { compose } from 'recompose';
import { analyticsConfigQuery, dashboardQuery } from '../../graph/dashboard';
import Filters from './analytics/Filters';
import GraphLayout from './analytics/v1/GraphLayout';
import TitleAction from '../../se/components/TitleAction';
import withFilterQueryString from '../../se/hocs/withFilterQueryString';
import { withSession } from '../../state/Session';
import { unpackSessionObject } from './unpackSessionObject';
import { CenteredSpinner } from '../../se/components/Spinner';
import Box from '@material-ui/core/Box';
import { useQuery } from '@apollo/client';
import { NamedRange } from '../core/DatePicker';
import withStyles from '@material-ui/core/styles/withStyles';
import HospitalRating from './analytics/HospitalRating';
import PWAInstallBanner from '../../se/components/PWAInstallBanner';
import pick from 'lodash/fp/pick';
import { RouteComponentProps } from 'react-router';
import { useScope } from '../../hooks/useScope';
import Header from './analytics/components/Header';
import AnalyticsV2 from './analytics/v2/AnalyticsV2';

const Stats = withStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: `1fr 1fr 1fr 1fr`,
    gridGap: theme.spacing(1),

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr 1fr auto',
    },
  },
}))(Box);

export const roundValue = (value: number, digits: number = 1) =>
  value ? (Math.round(value * 100) / 100).toFixed(digits) : 0;

export const AnalyticsLayout: FC<{ loading: boolean; children: React.ReactNode }> = ({ loading, children }) =>
  !loading ? <Stats>{children}</Stats> : <CenteredSpinner style={{ height: '30em' }} />;

const pickFilter = pick(['physician', 'procedureType', 'dateRange']);

const AnalyticsV1: FC<{ filter: any; setFilter: any; isBusinessManager: boolean }> = ({
  filter,
  setFilter,
  isBusinessManager,
}) => {
  const { dateRange, ...rest } = filter || {};
  // @ts-ignore

  const analyticsConfig = useQuery(analyticsConfigQuery);

  const popiMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.popiMinThreshold;
  const popiMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.popiMaxThreshold;
  const wrMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.wrMinThreshold;
  const wrMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.wrMaxThreshold;
  const preOpMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.preOpMinThreshold;
  const preOpMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.preOpMaxThreshold;
  const cleaningMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.cleaningMinThreshold;
  const cleaningMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.cleaningMaxThreshold;
  const pacuMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.pacuMinThreshold;
  const pacuMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.pacuMaxThreshold;
  const postOpMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.postOpMinThreshold;
  const postOpMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.postOpMaxThreshold;
  const inOrMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.inOrMinThreshold;
  const inOrMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.inOrMaxThreshold;
  const samePhysicianORTurnover = analyticsConfig?.data?.analyticsConfiguration?.samePhysicianORTurnover;
  const closingMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.closingMinThreshold;
  const closingMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.closingMaxThreshold;
  const { data, loading } = useQuery(dashboardQuery, {
    variables: {
      filter: { dateRange: dateRange.toJSON(), ...pickFilter(rest) },
      wrMin: wrMinThreshold,
      wrMax: wrMaxThreshold,
      preopMin: preOpMinThreshold,
      preopMax: preOpMaxThreshold,
      orMin: inOrMinThreshold,
      orMax: inOrMaxThreshold,
      cleaningMin: cleaningMinThreshold,
      cleaningMax: cleaningMaxThreshold,
      popiMin: popiMinThreshold,
      popiMax: popiMaxThreshold,
      pacuMin: pacuMinThreshold,
      pacuMax: pacuMaxThreshold,
      postopMin: postOpMinThreshold,
      postopMax: postOpMaxThreshold,
      samePhysicianORTurnover: samePhysicianORTurnover,
      closingMinThreshold: closingMinThreshold,
      closingMaxThreshold: closingMaxThreshold,
    },
    fetchPolicy: 'network-only',
  });
  const statistics = data?.dashboard || [];

  return (
    <Fragment>
      <TitleAction
        Title={() => <Header filter={filter} />}
        actionStyle={{
          style: { gridTemplateColumns: '1fr', alignItems: 'start' },
        }}
      >
        <Filters onChange={setFilter} value={filter} />
      </TitleAction>

      <PWAInstallBanner />

      {!isBusinessManager && <HospitalRating filter={filter} />}
      <AnalyticsLayout filter={filter} setFilter={setFilter} loading={loading}>
        {statistics.map((stat, i) => (
          <GraphLayout key={`${stat.id}-${i}`} {...stat} />
        ))}
      </AnalyticsLayout>
    </Fragment>
  );
};

const Analytics: FC<
  {
    filter: any;
    setFilter: any;
    isBusinessManager: boolean;
    physicianIdFromMetadata: number;
  } & RouteComponentProps
> = ({ filter, setFilter, isBusinessManager, physicianIdFromMetadata, location, match }) => {
  const scope = useScope();
  const analyticsV2 = scope?.hospital?.modules?.analyticsV2;

  const modifiedFilter = useMemo(() => {
    if (physicianIdFromMetadata) {
      return {
        ...filter,
        physicians: [physicianIdFromMetadata],
      };
    }
    return filter;
  }, [filter, physicianIdFromMetadata]);

  if (analyticsV2) {
    return (
      <AnalyticsV2
        location={location}
        match={match}
        filter={modifiedFilter}
        setFilter={setFilter}
        isBusinessManager={isBusinessManager}
        physicianIdFromMetadata={physicianIdFromMetadata}
      />
    );
  }

  return <AnalyticsV1 filter={modifiedFilter} setFilter={setFilter} isBusinessManager={isBusinessManager} />;
};

export default compose(
  withSession(unpackSessionObject),
  withFilterQueryString({
    dateRange: NamedRange.lastSevenDays(),
  })
  // @ts-ignore
)(Analytics);
